import Link from "next/link";
import Marquee from "react-fast-marquee";
import CMMIDEV3Svg from "../svg/CMMIDEV3Svg";
import G2LogoSvg from "../svg/Home/G2LogoSvg";
import GoodFirmsSvg from "../svg/Home/GoodFirmsSvg";
import ISO270032013Svg from "../svg/Home/ISO270032013Svg";
import ISO90012015Svg from "../svg/Home/ISO90012015Svg";
import SoftwareWorldLogoSvg from "../svg/Home/SoftwareWorldLogoSvg";
export default function MarqueeLogoCertificate(props) {
  return (
    <Marquee
      gradient
      speed="30"
      loop={0} // 0 means infinite looping
      delay={-0}
      autoFill="true"
      gradientColor="transferent"
      gradientWidth="100px"
      className=" certificate w-full p-t-section flex gap-[20px] items-baseline justify-center  lg:my-5 lg:py-[1.5rem] overflow-hidden"
      direction="left"
    >

      <Link href="https://www.g2.com/products/it-services-india/reviews#reviews" target="_blank" aria-label="G2 Reviews">
        <G2LogoSvg />
      </Link>
      <Link href="https://www.goodfirms.co/company/it-services-india" target="_blank" aria-label="Capterra Reviews">
        <GoodFirmsSvg />
      </Link>
      <Link href="https://www.softwareworld.co/service/it-services-india-reviews/" target="_blank" aria-label="softwareworld Reviews">
        <SoftwareWorldLogoSvg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2015">
        <ISO90012015Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2013">
        <ISO270032013Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="CMMI certification">
        <CMMIDEV3Svg />
      </Link>

      <Link href="https://www.g2.com/products/it-services-india/reviews#reviews" target="_blank" aria-label="G2 Reviews">
        <G2LogoSvg />
      </Link>
      <Link href="https://www.goodfirms.co/company/it-services-india" target="_blank" aria-label="Capterra Reviews">
        <GoodFirmsSvg />
      </Link>
      <Link href="https://www.softwareworld.co/service/it-services-india-reviews/" target="_blank" aria-label="softwareworld Reviews">
        <SoftwareWorldLogoSvg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2015">
        <ISO90012015Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2013">
        <ISO270032013Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="CMMI certification">
        <CMMIDEV3Svg />
      </Link>

      <Link href="https://www.g2.com/products/it-services-india/reviews#reviews" target="_blank" aria-label="G2 Reviews">
        <G2LogoSvg />
      </Link>
      <Link href="https://www.goodfirms.co/company/it-services-india" target="_blank" aria-label="Capterra Reviews">
        <GoodFirmsSvg />
      </Link>
      <Link href="https://www.softwareworld.co/service/it-services-india-reviews/" target="_blank" aria-label="softwareworld Reviews">
        <SoftwareWorldLogoSvg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2015">
        <ISO90012015Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2013">
        <ISO270032013Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="CMMI certification">
        <CMMIDEV3Svg />
      </Link>

      <Link href="https://www.g2.com/products/it-services-india/reviews#reviews" target="_blank" aria-label="G2 Reviews">
        <G2LogoSvg />
      </Link>
      <Link href="https://www.goodfirms.co/company/it-services-india" target="_blank" aria-label="Capterra Reviews">
        <GoodFirmsSvg />
      </Link>
      <Link href="https://www.softwareworld.co/service/it-services-india-reviews/" target="_blank" aria-label="softwareworld Reviews">
        <SoftwareWorldLogoSvg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2015">
        <ISO90012015Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2013">
        <ISO270032013Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="CMMI certification">
        <CMMIDEV3Svg />
      </Link>

      <Link href="https://www.g2.com/products/it-services-india/reviews#reviews" target="_blank" aria-label="G2 Reviews">
        <G2LogoSvg />
      </Link>
      <Link href="https://www.goodfirms.co/company/it-services-india" target="_blank" aria-label="Capterra Reviews">
        <GoodFirmsSvg />
      </Link>
      <Link href="https://www.softwareworld.co/service/it-services-india-reviews/" target="_blank" aria-label="softwareworld Reviews">
        <SoftwareWorldLogoSvg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2015">
        <ISO90012015Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2013">
        <ISO270032013Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="CMMI certification">
        <CMMIDEV3Svg />
      </Link>

      <Link href="https://www.g2.com/products/it-services-india/reviews#reviews" target="_blank" aria-label="G2 Reviews">
        <G2LogoSvg />
      </Link>
      <Link href="https://www.goodfirms.co/company/it-services-india" target="_blank" aria-label="Capterra Reviews">
        <GoodFirmsSvg />
      </Link>
      <Link href="https://www.softwareworld.co/service/it-services-india-reviews/" target="_blank" aria-label="softwareworld Reviews">
        <SoftwareWorldLogoSvg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2015">
        <ISO90012015Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="ISO 2013">
        <ISO270032013Svg />
      </Link>
      <Link href="/" target="_blank" aria-label="CMMI certification">
        <CMMIDEV3Svg />
      </Link>

    </Marquee>
  );
}
