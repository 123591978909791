import React from "react";

export default function G2LogoSvg() {
  return (
    <svg
      width="92"
      height="115"
      viewBox="0 0 92 115"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M68.7451 23.4956C68.7451 36.4745 58.2284 46.9911 45.2495 46.9911C32.2705 46.9911 21.7539 36.4745 21.7539 23.4956C21.7539 10.5166 32.2705 0 45.2495 0C58.2284 0 68.7451 10.526 68.7451 23.4956Z"
        fill="#FF492C"
      />
      <path
        d="M55.4181 19.6608H49.3468V19.3788C49.3468 18.345 49.5536 17.4898 49.9671 16.8225C50.3806 16.1458 51.0949 15.5538 52.1287 15.0275L52.5986 14.7925C53.4351 14.3696 53.6512 14.003 53.6512 13.5707C53.6512 13.0538 53.2001 12.6779 52.4764 12.6779C51.6118 12.6779 50.9633 13.129 50.5122 14.0406L49.3468 12.8753C49.6006 12.3302 50.0141 11.8978 50.5592 11.5501C51.1137 11.2024 51.7246 11.0332 52.3918 11.0332C53.2283 11.0332 53.952 11.2494 54.544 11.7005C55.1549 12.1516 55.4557 12.7719 55.4557 13.5519C55.4557 14.8019 54.7508 15.5632 53.435 16.2398L52.6926 16.6158C51.9031 17.0105 51.5178 17.3676 51.405 17.9973H55.4181V19.6608ZM54.8824 21.578H48.2378L44.9203 27.3297H51.5648L54.8918 33.0815L58.2093 27.3297L54.8824 21.578ZM45.4935 31.1736C41.2643 31.1736 37.8246 27.7339 37.8246 23.5047C37.8246 19.2755 41.2643 15.8357 45.4935 15.8357L48.125 10.3377C47.2698 10.1686 46.3958 10.084 45.4935 10.084C38.0783 10.084 32.0635 16.0989 32.0635 23.5047C32.0635 30.9199 38.0689 36.9347 45.4935 36.9347C48.4446 36.9347 51.1795 35.9761 53.3975 34.3596L50.484 29.3222C49.1495 30.4687 47.4014 31.1736 45.4935 31.1736Z"
        fill="white"
      />
      <path
        d="M7.56289 69.7947L9.63974 74.0168L14.2824 74.694C14.3413 74.7027 14.3966 74.7278 14.442 74.7663C14.4875 74.8048 14.5213 74.8552 14.5397 74.9119C14.558 74.9685 14.5602 75.0292 14.5459 75.087C14.5317 75.1449 14.5015 75.1976 14.4589 75.2392L11.1001 78.5249L11.8932 83.1653C11.9034 83.2239 11.897 83.2843 11.8746 83.3395C11.8523 83.3947 11.815 83.4425 11.7668 83.4776C11.7187 83.5126 11.6617 83.5335 11.6023 83.5378C11.5429 83.5421 11.4835 83.5297 11.4308 83.502L7.27809 81.3115L3.12534 83.5026C3.07269 83.5304 3.01328 83.543 2.95387 83.5387C2.89447 83.5345 2.83745 83.5136 2.78929 83.4786C2.74113 83.4435 2.70376 83.3957 2.68144 83.3405C2.65912 83.2853 2.65273 83.2249 2.66301 83.1662L3.45603 78.5249L0.0963002 75.2392C0.0537004 75.1976 0.0235719 75.1449 0.00930762 75.087C-0.00495666 75.0292 -0.00279013 74.9685 0.0155632 74.9119C0.0339166 74.8552 0.0677279 74.8048 0.113189 74.7663C0.158651 74.7278 0.213956 74.7027 0.272878 74.694L4.91549 74.0168L6.99329 69.7947C7.0192 69.7412 7.05966 69.6961 7.11003 69.6645C7.1604 69.633 7.21864 69.6162 7.27809 69.6162C7.33754 69.6162 7.39578 69.633 7.44615 69.6645C7.49652 69.6961 7.53698 69.7412 7.56289 69.7947Z"
        fill="#64748B"
      />
      <path
        d="M26.7572 69.7947L28.8341 74.0168L33.4767 74.694C33.5356 74.7027 33.5909 74.7278 33.6364 74.7663C33.6818 74.8048 33.7156 74.8552 33.734 74.9119C33.7524 74.9685 33.7545 75.0292 33.7403 75.087C33.726 75.1449 33.6959 75.1976 33.6533 75.2392L30.2945 78.5249L31.0875 83.1653C31.0977 83.2239 31.0913 83.2843 31.069 83.3395C31.0466 83.3947 31.0093 83.4425 30.9612 83.4776C30.913 83.5126 30.856 83.5335 30.7967 83.5378C30.7373 83.5421 30.6779 83.5297 30.6252 83.502L26.4724 81.3115L22.3197 83.5026C22.267 83.5304 22.2076 83.543 22.1482 83.5387C22.0888 83.5345 22.0318 83.5136 21.9836 83.4786C21.9355 83.4435 21.8981 83.3957 21.8758 83.3405C21.8535 83.2853 21.8471 83.2249 21.8573 83.1662L22.6504 78.5249L19.2906 75.2392C19.248 75.1976 19.2179 75.1449 19.2036 75.087C19.1894 75.0292 19.1915 74.9685 19.2099 74.9119C19.2283 74.8552 19.2621 74.8048 19.3075 74.7663C19.353 74.7278 19.4083 74.7027 19.4672 74.694L24.1098 74.0168L26.1876 69.7947C26.2135 69.7412 26.254 69.6961 26.3044 69.6645C26.3547 69.633 26.413 69.6162 26.4724 69.6162C26.5319 69.6162 26.5901 69.633 26.6405 69.6645C26.6909 69.6961 26.7313 69.7412 26.7572 69.7947Z"
        fill="#64748B"
      />
      <path
        d="M45.9545 69.7947L48.0313 74.0168L52.674 74.694C52.7329 74.7027 52.7882 74.7278 52.8336 74.7663C52.8791 74.8048 52.9129 74.8552 52.9313 74.9119C52.9496 74.9685 52.9518 75.0292 52.9375 75.087C52.9233 75.1449 52.8931 75.1976 52.8505 75.2392L49.4918 78.5249L50.2848 83.1653C50.295 83.2239 50.2886 83.2843 50.2662 83.3395C50.2439 83.3947 50.2066 83.4425 50.1584 83.4776C50.1103 83.5126 50.0533 83.5335 49.9939 83.5378C49.9345 83.5421 49.8751 83.5297 49.8224 83.502L45.6697 81.3115L41.5169 83.5026C41.4643 83.5304 41.4049 83.543 41.3455 83.5387C41.2861 83.5345 41.229 83.5136 41.1809 83.4786C41.1327 83.4435 41.0954 83.3957 41.073 83.3405C41.0507 83.2853 41.0443 83.2249 41.0546 83.1662L41.8476 78.5249L38.4879 75.2392C38.4453 75.1976 38.4152 75.1449 38.4009 75.087C38.3866 75.0292 38.3888 74.9685 38.4072 74.9119C38.4255 74.8552 38.4593 74.8048 38.5048 74.7663C38.5503 74.7278 38.6056 74.7027 38.6645 74.694L43.3071 74.0168L45.3849 69.7947C45.4108 69.7412 45.4513 69.6961 45.5016 69.6645C45.552 69.633 45.6102 69.6162 45.6697 69.6162C45.7291 69.6162 45.7874 69.633 45.8378 69.6645C45.8881 69.6961 45.9286 69.7412 45.9545 69.7947Z"
        fill="#64748B"
      />
      <path
        d="M65.1505 69.7947L67.2274 74.0168L71.87 74.694C71.9289 74.7027 71.9842 74.7278 72.0297 74.7663C72.0751 74.8048 72.109 74.8552 72.1273 74.9119C72.1457 74.9685 72.1478 75.0292 72.1336 75.087C72.1193 75.1449 72.0892 75.1976 72.0466 75.2392L68.6878 78.5249L69.4808 83.1653C69.491 83.2239 69.4846 83.2843 69.4623 83.3395C69.44 83.3947 69.4026 83.4425 69.3545 83.4776C69.3063 83.5126 69.2494 83.5335 69.19 83.5378C69.1306 83.5421 69.0712 83.5297 69.0185 83.502L64.8657 81.3115L60.713 83.5026C60.6603 83.5304 60.6009 83.543 60.5415 83.5387C60.4821 83.5345 60.4251 83.5136 60.3769 83.4786C60.3288 83.4435 60.2914 83.3957 60.2691 83.3405C60.2468 83.2853 60.2404 83.2249 60.2507 83.1662L61.0437 78.5249L57.6839 75.2392C57.6413 75.1976 57.6112 75.1449 57.597 75.087C57.5827 75.0292 57.5849 74.9685 57.6032 74.9119C57.6216 74.8552 57.6554 74.8048 57.7008 74.7663C57.7463 74.7278 57.8016 74.7027 57.8605 74.694L62.5031 74.0168L64.5809 69.7947C64.6068 69.7412 64.6473 69.6961 64.6977 69.6645C64.748 69.633 64.8063 69.6162 64.8657 69.6162C64.9252 69.6162 64.9834 69.633 65.0338 69.6645C65.0842 69.6961 65.1246 69.7412 65.1505 69.7947Z"
        fill="#64748B"
      />
      <path
        d="M84.348 69.7947L86.4249 74.0168L91.0675 74.694C91.1264 74.7027 91.1817 74.7278 91.2272 74.7663C91.2727 74.8048 91.3065 74.8552 91.3248 74.9119C91.3432 74.9685 91.3453 75.0292 91.3311 75.087C91.3168 75.1449 91.2867 75.1976 91.2441 75.2392L87.8853 78.5249L88.6783 83.1653C88.6885 83.2239 88.6821 83.2843 88.6598 83.3395C88.6375 83.3947 88.6001 83.4425 88.552 83.4776C88.5039 83.5126 88.4469 83.5335 88.3875 83.5378C88.3281 83.5421 88.2687 83.5297 88.216 83.502L84.0632 81.3115L79.9105 83.5026C79.8578 83.5304 79.7984 83.543 79.739 83.5387C79.6796 83.5345 79.6226 83.5136 79.5744 83.4786C79.5263 83.4435 79.4889 83.3957 79.4666 83.3405C79.4443 83.2853 79.4379 83.2249 79.4482 83.1662L80.2412 78.5249L76.8815 75.2392C76.8389 75.1976 76.8087 75.1449 76.7945 75.087C76.7802 75.0292 76.7824 74.9685 76.8007 74.9119C76.8191 74.8552 76.8529 74.8048 76.8983 74.7663C76.9438 74.7278 76.9991 74.7027 77.058 74.694L81.7006 74.0168L83.7784 69.7947C83.8044 69.7412 83.8448 69.6961 83.8952 69.6645C83.9456 69.633 84.0038 69.6162 84.0632 69.6162C84.1227 69.6162 84.1809 69.633 84.2313 69.6645C84.2817 69.6961 84.3221 69.7412 84.348 69.7947Z"
        fill="#64748B"
      />
      <path
        className="dark-txt"
        d="M25.9963 96.1122V94.2662H21.4463V92.8362L25.6973 86.7522H27.8163V92.6802H28.9603V94.2662H27.8163V96.1122H25.9963ZM23.4483 92.6802H26.1133V88.7022L23.4483 92.6802ZM33.238 95.1372C33.238 95.7352 32.77 96.2032 32.133 96.2032C31.47 96.2032 31.002 95.7352 31.002 95.1372C31.002 94.5392 31.47 94.0712 32.133 94.0712C32.77 94.0712 33.238 94.5392 33.238 95.1372ZM35.262 86.6612H41.528V88.0132L38.07 96.1122H36.224L39.708 88.2212H35.262V86.6612ZM56.7767 91.5492C56.7767 94.2792 54.7097 96.2032 52.1357 96.2032C49.5747 96.2032 47.4817 94.2792 47.4817 91.5492C47.4817 88.8322 49.5747 86.9082 52.1357 86.9082C54.7227 86.9082 56.7767 88.8322 56.7767 91.5492ZM49.3537 91.5492C49.3537 93.3952 50.4847 94.5782 52.1357 94.5782C53.7737 94.5782 54.9047 93.3952 54.9047 91.5492C54.9047 89.7032 53.7737 88.5462 52.1357 88.5462C50.4847 88.5462 49.3537 89.7032 49.3537 91.5492ZM65.0717 87.0252H66.8917V96.1122H65.0717L60.9507 89.8852V96.1122H59.1307V87.0252H60.9507L65.0717 93.2652V87.0252ZM40.6756 104.921C42.7036 104.921 44.3156 105.948 44.9006 107.768H42.8076C42.3916 107.001 41.6376 106.598 40.6756 106.598C39.0506 106.598 37.9196 107.755 37.9196 109.562C37.9196 111.408 39.0636 112.552 40.7536 112.552C42.1576 112.552 43.0546 111.746 43.3276 110.459H40.2076V109.068H45.1216V110.654C44.7576 112.5 43.1066 114.19 40.6886 114.19C38.0496 114.19 36.0476 112.279 36.0476 109.562C36.0476 106.845 38.0496 104.921 40.6756 104.921ZM47.1561 113.982V112.63C49.3011 110.81 51.5501 109.133 51.5501 107.391C51.5501 106.546 51.1731 105.974 50.2761 105.974C49.4051 105.974 48.9241 106.598 48.9111 107.599H47.1431C47.2081 105.428 48.5991 104.427 50.3021 104.427C52.3431 104.427 53.3701 105.636 53.3701 107.287C53.3701 109.484 51.2511 111.2 49.7431 112.474H53.5781V113.982H47.1561Z"
        fill="#0F172A"
      />
    </svg>
  );
}
