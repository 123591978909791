import React from 'react'

export default function SoftwareWorldLogoSvg() {
  return (
    <svg width="64" height="106" viewBox="0 0 64 106" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="dark-txt" d="M3.9724 91.1671C5.14698 91.1671 6.08403 90.8768 6.78347 90.2961C7.48299 89.7022 7.83928 88.9631 7.83928 88.0524C7.83928 86.8779 7.21905 86.0201 5.97842 85.4922C5.72773 85.3866 5.34495 85.2414 4.85665 85.0698L3.9988 84.7531C3.4841 84.5552 3.22016 84.3176 3.22016 84.0272C3.22016 83.6709 3.5897 83.3938 4.26282 83.3938C5.00181 83.3938 5.96527 83.7237 6.54594 84.1988L7.69411 82.3248C6.70434 81.5065 5.55617 81.0974 4.22318 81.0974C3.1542 81.0974 2.2832 81.3877 1.61008 81.9553C0.937048 82.5228 0.59391 83.2486 0.59391 84.1064C0.59391 85.2282 1.13502 86.0728 2.20399 86.6403C2.48109 86.7855 2.90343 86.9439 3.44454 87.1419L4.31554 87.4322C4.84349 87.6302 5.10742 87.8809 5.10742 88.1845C5.10742 88.6464 4.60596 88.8971 4.01205 88.8971C2.98263 88.8971 2.05883 88.488 1.24054 87.6698L0 89.4778C1.01617 90.5996 2.34916 91.1671 3.9724 91.1671Z" fill="#013870"/>
<path class="dark-txt" d="M12.6301 91.1934C13.8047 91.1934 14.7681 90.8238 15.494 90.0848C16.233 89.3458 16.6025 88.4351 16.6025 87.3529C16.6025 86.2707 16.233 85.3601 15.494 84.621C14.7681 83.8688 13.8047 83.4993 12.6301 83.4993C11.4556 83.4993 10.5054 83.8688 9.76628 84.621C9.04044 85.3601 8.6709 86.2707 8.6709 87.3529C8.6709 88.4351 9.04044 89.3458 9.76628 90.0848C10.5054 90.8238 11.4556 91.1934 12.6301 91.1934ZM12.6301 89.0158C12.1682 89.0158 11.7855 88.8574 11.4687 88.5406C11.152 88.2108 10.9937 87.8148 10.9937 87.3397C10.9937 86.8778 11.152 86.4951 11.4687 86.1783C11.7855 85.8484 12.1682 85.69 12.6301 85.69C13.092 85.69 13.4747 85.8484 13.7915 86.1652C14.1215 86.4819 14.2798 86.8778 14.2798 87.3397C14.2798 87.8148 14.1215 88.2108 13.8047 88.5406C13.488 88.8574 13.092 89.0158 12.6301 89.0158Z" fill="#013870"/>
<path class="dark-txt" d="M21.0598 82.5227C21.403 82.5227 21.8253 82.6414 22.3136 82.879L23.0922 81.137C22.2608 80.7146 21.4558 80.5034 20.6771 80.5034C19.7532 80.5034 19.001 80.7674 18.4203 81.2821C17.8396 81.7968 17.5493 82.5622 17.5493 83.5521V83.7105V85.6373V90.9955H19.872V85.6373H22.1024V83.7105H19.872V83.6049C19.872 82.9186 20.3208 82.5227 21.0598 82.5227Z" fill="#013870"/>
<path class="dark-txt" d="M27.8913 88.7256C27.469 88.95 27.0863 89.0556 26.7431 89.0556C26.1228 89.0556 25.8192 88.6729 25.8192 87.8942V85.6375H28.1025V83.7106H25.8192V81.1238L23.5097 82.0576C23.5097 85.4011 23.4834 84.7377 23.4834 88.1713C23.4834 90.1774 24.3808 91.1804 26.1889 91.1804C27.0599 91.1804 27.8385 90.9825 28.5115 90.5865L27.8913 88.7256Z" fill="#013870"/>
<path class="dark-txt" d="M33.1699 90.9955L34.5292 87.122L35.9017 90.9955H37.9737L40.4549 83.7104H38.0001L36.7596 87.9534L35.3474 83.7104H33.711L32.3253 87.9534L31.0715 83.7104H28.6167L31.0979 90.9955H33.1699Z" fill="#013870"/>
<path class="dark-txt" d="M46.3546 84.3705C45.7608 83.803 45.0481 83.5259 44.2034 83.5259C43.1741 83.5259 42.3163 83.8822 41.6431 84.608C40.9701 85.3207 40.6401 86.2313 40.6401 87.3267C40.6401 88.4221 40.9701 89.3327 41.6431 90.0718C42.3163 90.8109 43.1609 91.1803 44.2034 91.1803C45.0481 91.1803 45.7608 90.9033 46.3546 90.3621V90.9956H48.5455V83.7106H46.3546V84.3705ZM46.3283 87.34C46.3283 87.815 46.1699 88.2241 45.8399 88.5541C45.51 88.884 45.1141 89.0556 44.639 89.0556C44.1639 89.0556 43.7679 88.884 43.438 88.5541C43.1213 88.2241 42.9629 87.815 42.9629 87.34C42.9629 86.878 43.1213 86.4821 43.438 86.1521C43.7548 85.8222 44.1507 85.6507 44.639 85.6507C45.1141 85.6507 45.51 85.8222 45.8399 86.1521C46.1699 86.4821 46.3283 86.878 46.3283 87.34Z" fill="#013870"/>
<path class="dark-txt" d="M52.3462 90.9957V87.8547C52.3462 86.4558 52.8873 85.7562 53.9563 85.7562C54.2598 85.7562 54.5765 85.8091 54.9196 85.9278L55.1045 83.7107C54.8141 83.5787 54.5105 83.5127 54.1938 83.5127C53.3624 83.5127 52.7157 83.8822 52.2538 84.6081V83.7107H50.0234V90.9957H52.3462Z" fill="#013870"/>
<path class="dark-txt" d="M63.0232 87.1684C63.0232 86.0466 62.6669 85.1491 61.9674 84.5025C61.2679 83.8558 60.3705 83.5259 59.2883 83.5259C58.1401 83.5259 57.2031 83.8954 56.4772 84.6213C55.7514 85.3471 55.395 86.2577 55.395 87.3531C55.395 88.4221 55.7382 89.3196 56.4377 90.0586C57.1371 90.7845 58.1269 91.154 59.4203 91.154C60.6344 91.154 61.7034 90.8241 62.6405 90.151L61.7298 88.5805C61.0568 89.0292 60.3309 89.2535 59.5258 89.2535C58.602 89.2535 58.0081 88.8708 57.7442 88.1054H62.944C62.9968 87.7755 63.0232 87.4719 63.0232 87.1684ZM59.2883 85.3867C60.0669 85.3867 60.6081 85.809 60.806 86.5085H57.7178C57.9685 85.7562 58.4833 85.3867 59.2883 85.3867Z" fill="#013870"/>
<path d="M5.51661 105.802L7.20589 100.127L8.89509 105.802H11.2575L14.3985 96.0491H11.6666L10.017 102.146L8.07689 96.0491H6.32164L4.39483 102.146L2.73194 96.0491H0L3.14104 105.802H5.51661Z" fill="#38B6FF"/>
<path d="M17.9754 106C19.1499 106 20.1133 105.63 20.8392 104.891C21.5782 104.152 21.9478 103.242 21.9478 102.16C21.9478 101.077 21.5782 100.167 20.8392 99.4277C20.1133 98.6754 19.1499 98.3059 17.9754 98.3059C16.8008 98.3059 15.8506 98.6754 15.1115 99.4277C14.3857 100.167 14.0161 101.077 14.0161 102.16C14.0161 103.242 14.3857 104.152 15.1115 104.891C15.8506 105.63 16.8008 106 17.9754 106ZM17.9754 103.822C17.5135 103.822 17.1307 103.664 16.8139 103.347C16.4972 103.017 16.3389 102.621 16.3389 102.146C16.3389 101.684 16.4972 101.302 16.8139 100.985C17.1307 100.655 17.5135 100.497 17.9754 100.497C18.4373 100.497 18.82 100.655 19.1367 100.972C19.4667 101.289 19.625 101.684 19.625 102.146C19.625 102.621 19.4667 103.017 19.1499 103.347C18.8332 103.664 18.4373 103.822 17.9754 103.822Z" fill="#38B6FF"/>
<path d="M25.4058 105.802V102.661C25.4058 101.262 25.9469 100.562 27.0158 100.562C27.3193 100.562 27.6361 100.615 27.9792 100.734L28.164 98.5168C27.8737 98.3848 27.5701 98.3188 27.2534 98.3188C26.4219 98.3188 25.7753 98.6884 25.3133 99.4142V98.5168H23.083V105.802H25.4058Z" fill="#38B6FF"/>
<path d="M31.2916 95.7322L28.9688 96.6659V105.802H31.2916V95.7322Z" fill="#38B6FF"/>
<path d="M37.956 99.0844C37.4282 98.5828 36.7682 98.3321 35.9896 98.3321C34.9734 98.3321 34.1287 98.7016 33.4424 99.4275C32.7694 100.153 32.4263 101.064 32.4263 102.159C32.4263 103.268 32.7694 104.179 33.4424 104.905C34.1156 105.63 34.9734 105.987 35.9896 105.987C36.7946 105.987 37.4809 105.71 38.022 105.168V105.802H40.292V95.7322L37.956 96.6659V99.0844ZM36.3855 103.862C35.9104 103.862 35.5144 103.703 35.1978 103.374C34.881 103.044 34.7225 102.634 34.7225 102.146C34.7225 101.684 34.881 101.288 35.1978 100.958C35.5144 100.629 35.9104 100.457 36.3855 100.457C37.349 100.457 38.0484 101.196 38.0484 102.146C38.0484 103.11 37.349 103.862 36.3855 103.862Z" fill="#38B6FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.1945 0H52.8055C56.7625 0 60 3.23724 60 7.19426V50.8055C60 54.7625 56.7625 58 52.8055 58H9.1945C5.23748 58 2 54.7625 2 50.8055V7.19426C2 3.23724 5.23748 0 9.1945 0Z" fill="#38B6FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.7241 32.5829C13.2342 33.443 15.2945 35.2266 16.0274 35.9728L28.9941 30.9876C28.7266 32.3233 25.3925 38.6294 24.4046 40.6136C23.9274 41.5715 23.1549 42.8477 22.8168 43.8649C23.0497 44.3199 25.7227 47.5214 26.2177 47.7695C27.0625 46.7424 38.67 26.066 38.8143 25.4583L36.2396 22.744C35.2934 22.7934 30.3134 24.8724 29.1281 25.3221L22.0756 27.9502C22.322 26.9616 24.9237 22.8588 25.6953 21.5478C26.3091 20.505 29.2052 15.8938 29.3815 15.0934C28.6176 14.162 25.669 10.5786 24.9325 10.231L12.7241 32.5829Z" fill="#FEFEFE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.0854 45.0968C32.1621 44.8666 48.8983 38.0922 49.2762 37.789C49.0113 37.2231 45.7376 33.4042 45.3471 33.0745C44.4884 33.1744 37.3264 36.3561 36.0507 36.8979C34.8325 37.4151 34.4057 38.8821 33.3474 40.8022C32.9428 41.5368 31.2906 44.3193 31.0854 45.0968Z" fill="#FEFEFE"/>
</svg>

    

  )
}
